// Scroll left
export const scrollLeft = (scrollContainerRef: any) => {
  if (scrollContainerRef.current) {
    scrollContainerRef.current.scrollBy({ left: -500, behavior: "smooth" });
  }
};

// Scroll right
export const scrollRight = (scrollContainerRef: any) => {
  if (scrollContainerRef.current) {
    scrollContainerRef.current.scrollBy({ left: 500, behavior: "smooth" });
  }
};

// Scroll to top
export const scrollToTop = () => {
  // This function will scroll the window to the top
  window.scrollTo({ top: 0, behavior: "smooth" });
};
