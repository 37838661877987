import { useGlobalContext } from "@/app/context/store";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { ChevronUp, Gem } from "lucide-react";
import { scrollToTop } from "@/components/shared/infiniteScroll/scroll";
import { getLocalizedPath } from "@/components/shared/formatURL";

export default function ScrollButton() {
  const { user } = useGlobalContext();
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const shouldShow = window.scrollY > 200;
      setShowScrollTop(shouldShow);
    };

    // Setup the scroll listener
    window.addEventListener("scroll", handleScroll);

    // Initial check and cleanup
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="fixed bottom-0 flex h-[6.25rem] w-full bg-gradient-to-t from-[#131315] via-[#131315]/70 to-transparent sm:bottom-16 sm:h-0 sm:max-w-[80rem] sm:bg-none xl:right-3">
      <button
        className={`${
          user.membership && user?.membership.level === 1 ? "hidden" : "block"
        } block flex w-auto pl-10 sm:hidden`}
        aria-label="Upgrade"
      >
        <Link
          prefetch={false}
          href={getLocalizedPath("upgrade") + "?ref=scroll"}
          aria-label="Upgrade"
          className="mb-auto mr-auto flex animate-fade-up-sped rounded-full bg-[#FFCC18] px-3 py-2 text-sm font-bold text-white shadow-2xl hover:brightness-105"
        >
          <Gem color="black" className="mx-0.5 my-auto h-5 w-auto px-[0.85px] py-0.5" />
        </Link>
      </button>
      {showScrollTop && (
        <div className={`z-1000 ml-auto pr-10`}>
          <button
            onClick={scrollToTop}
            className="flex animate-fade-up-sped rounded-full bg-[#DD0772] px-3 py-2 text-sm font-bold text-white shadow-2xl hover:brightness-105 sm:mt-[-2.25rem]"
          >
            <ChevronUp color="white" className="my-auto h-5 w-auto" />
          </button>
        </div>
      )}
    </div>
  );
}
