"use client";
import { useRef, useEffect, useState, useMemo } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import Image from "next/image";
import { ChevronUp, Expand, Move, ChevronRight, ChevronLeft } from "lucide-react";
import CardWrapper from "../pictureCard/cardWrapper";
import { useDisplayModeContext } from "@/app/context/displayModeStore";
import cookie from "cookie";
import { useRouter } from "next/navigation";
import ScrollButton from "./scrollButton";
import useSWRInfinite from "swr/infinite";
import axiosInstance from "@/lib/axios/axiosInstance";

interface Post {
  _id: string;
  author: string;
  createdAt: string;
  imageUrl: string;
  videoUrl: string;
  thumbnailUrl: string;
  likes: number;
  model: string;
  tagGroups: string[];
  updatedAt: string;
  userLiked: boolean;
  views: number;
}
interface PageData {
  info: {
    count: number;
    next: string | null;
    pages: number;
    prev: string | null;
  };
  results: Post[];
}

export function InfiniteScrollWrapper({
  contentType,
  translations,
  signInModalTranslations,
  modalTranslations,
  currTab,
  currGenerator,
  constructUrlFn,
  queryParam,
}: {
  contentType: string;
  translations: any;
  signInModalTranslations: any;
  modalTranslations: any;
  currTab: string;
  currGenerator: string;
  constructUrlFn: (tab: string, generator: string, index: number) => string;
  queryParam?: string;
}) {
  const { state, dispatch } = useDisplayModeContext();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();

  // Internal SWR setup
  const getKey = useMemo(() => {
    return (pageIndex: any, previousPageData: any) => {
      if (pageIndex === 0) {
        return constructUrlFn(currTab, currGenerator, pageIndex);
      }
      if (previousPageData && previousPageData.results.length < 10) {
        return null;
      }
      if (!previousPageData || previousPageData.info.next === null) {
        return null;
      }
      return previousPageData.info.next;
    };
  }, [currTab, currGenerator, constructUrlFn]);

  const fetcher = async (url: string) => {
    try {
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const { data, error, setSize, size } = useSWRInfinite(getKey, fetcher, {
    revalidateOnFocus: false,
    revalidateAll: false,
    revalidateFirstPage: false,
  });

  // UI States
  const [sliderValue, setSliderValue] = useState(1);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(6);
  const [fullScreen, setFullScreen] = useState(false);

  // Flatten all pages
  const infiniteScrollBoundaryRef = useRef<HTMLDivElement | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (data && typeof data[size - 1] === "undefined");
  const isReachingEnd =
    data &&
    (data[data.length - 1]?.info.next === null || data[data.length - 1]?.results.length === 0);
  const posts = useMemo(() => {
    if (!data) return [];

    // Use flat() for cleaner code, modern browsers optimize this internally
    return data.flatMap((page) => page.results);
  }, [data]);

  // ---- Slider changes ----
  const handleSliderChange = (event: any) => {
    setSliderValue(event.target.value);
    // Serialize the cookie string using the `cookie` library
    const serializedCookie = cookie.serialize("sliderValue", event.target.value, {
      path: "/",
      maxAge: 365 * 24 * 60 * 60 * 999,
    });
    document.cookie = serializedCookie;

    if (contentType === "gif") {
      // GIF layout settings
      switch (event.target.value) {
        case "0":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 2, lg: 2, md: 1, sm: 1 },
          });
          break;
        case "1":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 3, lg: 3, md: 2, sm: 2 },
          });
          break;
        case "2":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 4, lg: 4, md: 3, sm: 3 },
          });
          break;
        case "3":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 5, lg: 5, md: 3, sm: 3 },
          });
          break;
        default:
          console.error("Unexpected slider value");
      }
    } else {
      // Original image layout settings
      switch (event.target.value) {
        case "0":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 3, lg: 2, md: 2, sm: 1 },
          });
          break;
        case "1":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 4, lg: 3, md: 2, sm: 2 },
          });
          break;
        case "2":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 5, lg: 4, md: 3, sm: 3 },
          });
          break;
        case "3":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 6, lg: 5, md: 4, sm: 4 },
          });
          break;
        case "4":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 7, lg: 6, md: 5, sm: 5 },
          });
          break;
        case "5":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 8, lg: 7, md: 6, sm: 5 },
          });
          break;
        case "6":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 9, lg: 8, md: 7, sm: 6 },
          });
          break;
        default:
          console.error("Unexpected slider value");
      }
    }
  };

  // ---- Full screen toggling ----
  function toggleFullScreen() {
    setFullScreen(!fullScreen);
    if (!document.fullscreenElement) {
      // Enter full screen
      document.documentElement.requestFullscreen().catch((err) => {
        console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      // Exit full screen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  // ---- Expand content toggling ----
  function toggleExpandContent() {
    // Serialize the cookie string using the `cookie` library
    const serializedCookie = cookie.serialize("expandContent", String(!state.expandContent), {
      path: "/",
      maxAge: 365 * 24 * 60 * 60 * 999, // Max age in seconds
    });
    // Set the cookie in the browser
    document.cookie = serializedCookie;
    dispatch({ type: "SET_EXPAND_CONTENT", payload: "" });
  }

  const updateRangeValues = () => {
    const screenWidth = window.innerWidth;
    if (contentType === "gif") {
      if (screenWidth < 768) {
        setMinValue(0);
        setMaxValue(2); // Mobile: 1-3 columns
      } else {
        setMinValue(0);
        setMaxValue(2); // Desktop: 2-4 columns
      }
    } else {
      if (screenWidth < 768) {
        setMinValue(0);
        setMaxValue(4);
      } else {
        setMinValue(0);
        setMaxValue(6);
      }
    }
  };

  useEffect(() => {
    // ---- Manage screen resize + read cookies once on mount ----
    updateRangeValues();

    // Parse cookies for slider/expand states
    const parsedCookies = cookie.parse(document.cookie);
    if (parsedCookies.sliderValue) {
      handleSliderChange({ target: { value: parsedCookies.sliderValue } });
    }

    if (parsedCookies.expandContent) {
      if (parsedCookies.expandContent === "true") {
        dispatch({ type: "SET_EXPAND_CONTENT", payload: true });
      }
    }

    window.addEventListener("resize", updateRangeValues);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", updateRangeValues);
    };
  }, []); // Empty dependency array - only run once on mount

  useEffect(() => {
    if (contentType === "gif" && sliderValue > 2) {
      setSliderValue(2);
      dispatch({
        type: "SET_VIEWING_MODE",
        payload: { default: 4, lg: 4, md: 3, sm: 3 },
      });
    }
  }, [contentType]);

  // Fix scroll button issue by directly triggering an initial scroll event
  useEffect(() => {
    // Dispatch a synthetic scroll event to initialize the scroll button
    const scrollEvent = new Event("scroll");
    window.dispatchEvent(scrollEvent);
  }, []);

  useEffect(() => {
    // Force re-check scroll position to make scroll button appear correctly
    const triggerScrollCheck = () => {
      window.dispatchEvent(new Event("scroll"));
    };

    // Trigger scroll check after component mounts and data loads
    const initialCheckTimer = setTimeout(triggerScrollCheck, 500);

    // Trigger again each time data updates
    if (data && data.length > 0) {
      triggerScrollCheck();
    }

    return () => {
      clearTimeout(initialCheckTimer);
    };
  }, [data]);

  // Add a separate effect for the observer
  useEffect(() => {
    // Clean up previous observer if it exists
    if (observerRef.current) {
      observerRef.current.disconnect();
      observerRef.current = null;
    }

    // Only create a new observer if conditions are right
    if (!isLoadingMore && !error && infiniteScrollBoundaryRef.current) {
      observerRef.current = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && !isReachingEnd) {
            setSize((prevSize) => prevSize + 1);
          }
        },
        { threshold: 0, rootMargin: "300px" },
      );

      observerRef.current.observe(infiniteScrollBoundaryRef.current);

      // Check if already in view
      const boundaryRect = infiniteScrollBoundaryRef.current.getBoundingClientRect();
      const isAlreadyInView = boundaryRect.top < window.innerHeight + 300;

      if (isAlreadyInView && !isReachingEnd && !isLoadingMore) {
        setSize((prevSize) => prevSize + 1);
      }
    }

    // Cleanup on unmount
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
        observerRef.current = null;
      }
    };
  }, [isLoadingMore, isReachingEnd, error]);

  return (
    <>
      <div className="mx-auto px-5 xl:max-w-[88rem]">
        <div className="mb-3.5 mt-2 flex flex-row items-center justify-center">
          <div className="flex-grow">
            <input
              type="range"
              aria-label="Slider"
              min={minValue}
              max={maxValue}
              value={sliderValue}
              onChange={handleSliderChange}
              className="h-2 w-full cursor-pointer appearance-none rounded-lg border-[0.5px] border-[#3C3C3C] bg-[#2B2D31]"
            />
          </div>
          <button
            onClick={toggleExpandContent}
            className={`${
              false ? "" : ""
            } ml-4 hidden flex-row overflow-hidden whitespace-nowrap rounded-md py-[7px] text-sm font-medium text-white hover:brightness-105 focus:outline-none focus:ring-0 focus:ring-gray-300 sm:flex`}
          >
            <Move color="white" className="mx-1 my-auto h-3.5 w-auto" />
            {state.expandContent ? translations.shrinkContent : translations.expandContent}
          </button>
          <button
            onClick={toggleFullScreen}
            className={`${
              false ? "" : ""
            } ml-4 hidden flex-row rounded-md py-[7px] text-sm font-medium text-white hover:brightness-105 focus:outline-none focus:ring-0 focus:ring-gray-300 sm:flex`}
          >
            <Expand color="white" className="mx-1 my-auto h-3.5 w-auto" />
            {fullScreen ? translations.exitFullScreen : translations.fullScreen}
          </button>
        </div>
      </div>
      <div
        className={`mx-auto mb-[100px] ${
          state.expandContent ? "" : "px-0 sm:px-[7px] xl:max-w-[88rem]"
        }`}
      >
        {/* Images */}
        <CardWrapper
          data={posts}
          contentType={contentType}
          modalTranslations={modalTranslations}
          signInModalTranslations={signInModalTranslations}
        />

        <div ref={infiniteScrollBoundaryRef} className="-mt-[55vh]" />
        {/* Loading Icon */}
        {isLoadingMore && !isReachingEnd && !error && posts.length % 10 === 0 && (
          <div className="flex">
            <Image
              src={"https://cdn6.imgpog.com/loadingGIF.webp"}
              width={50}
              height={50}
              alt="Loading"
              className="mx-auto mb-[55vh] mt-[55vh]"
              unoptimized
            />
          </div>
        )}

        {isReachingEnd && posts.length === 0 && queryParam !== null && (
          <div className="flex px-5 font-bold text-white">
            {translations.cantFindPosts}"{queryParam}"
          </div>
        )}
      </div>
      <ScrollButton />
    </>
  );
}
