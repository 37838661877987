import Link from "next/link";

interface TabLinkProps {
  label: string; // e.g., 'top', 'new', 'all'
  current: string; // current tab or generator
  setCurrent: any; // function to set the current tab or generator
  isTab?: boolean; // differentiate between tab and generator
  newURL: string;
  contentType?: string;
  currTab?: any;
  currGenerator?: any;
  queryParam?: any;
  translations: any;
}

export const TabLink: React.FC<TabLinkProps> = ({
  label,
  current,
  setCurrent,
  isTab = true,
  newURL,
  contentType,
  currTab,
  currGenerator,
  queryParam,
  translations,
}) => {
  const query: any = {
    filter: isTab ? label : currTab,

    ...(queryParam && { search: queryParam }),
  };

  if (contentType !== "gif") {
    query.style = isTab ? currGenerator : label;
  }

  return (
    <Link
      prefetch={false}
      href={{ pathname: newURL, query }}
      onClick={() => setCurrent(label)}
      className={`${
        current === label
          ? "border-[0.5px] border-[#DD0772] bg-[#DD0772]"
          : "border-[0.5px] border-[#3C3C3C] bg-[#2B2D31]"
      } xs:ml-0 mr-2.5 whitespace-nowrap rounded-md px-4 py-[7px] text-sm font-medium text-white hover:brightness-105 focus:outline-none focus:ring-0 focus:ring-gray-300`}
    >
      {translations[label]}
    </Link>
  );
};
